import React from 'react';
import { graphql } from 'gatsby';
import * as Ink from '@carta/ink';

import Layout from '../components/Layout';
import Header from '../components/Header/Header';
import PreviewCard from '../components/Common/PreviewCard';

const Patterns = ({
  data: {
    allPatterns: { nodes },
  },
}) => {
  const sortedPatterns = nodes
    .filter(node => !node.slug.includes('/'))
    .sort((a, b) => a.frontmatter.title.localeCompare(b.frontmatter.title));

  return (
    <Layout pageTitle="Component Patterns">
      <Ink.Box bottom="16">
        <Header
          title="Component Patterns"
          subtitle="Make the most of ink components by reading our component patterns to address common design problems"
        />
      </Ink.Box>
      <Ink.Tiles columns={[1, 2, 2, 3, 4]}>
        {sortedPatterns.map(node => (
          <PreviewCard
            key={node.frontmatter.title}
            title={node.frontmatter.title}
            text={node.frontmatter.subtitle}
            actionLabel="View pattern"
            actionHref={`/patterns/${node.slug}`}
            previewWithCheckerboard
          />
        ))}
      </Ink.Tiles>
    </Layout>
  );
};

export default Patterns;

export const query = graphql`
  query PatternsQuery {
    allPatterns: allMdx(filter: { fileAbsolutePath: { regex: "/patterns/" } }) {
      nodes {
        frontmatter {
          title
          subtitle
        }
        slug
      }
    }
  }
`;
